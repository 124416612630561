<template>
  <iframe class="bg-white fill-width fill-height rounded" id="historyLogs" />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { selectedHost } from '@/config'

export default {
  name: 'HistoryLogs',
  computed: {
    ...mapGetters(['loggedUser']),
  },
  methods: {
    ...mapMutations(['SET_PROCESSING']),
    getHistoryLogs() {
      const vue = this
      document.querySelector('#historyLogs').onload = function (n) {
        vue.SET_PROCESSING(false)

        const iframe = document.getElementById('historyLogs')
        const innerDoc = iframe.contentDocument || iframe.contentWindow.document

        const el = innerDoc.querySelector('.v-toolbar__title')
        el.style.display = 'none'
      }
      document.querySelector(
        '#historyLogs'
      ).src = `${selectedHost}/Engine/${this.loggedUser.token}/static/history.htm`
    },
  },
  mounted() {
    this.SET_PROCESSING(true)
    this.getHistoryLogs()
  },
}
</script>

<style scoped src="./HistoryLogs.css"></style>
